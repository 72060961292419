import { Link } from 'react-router-dom';
import EventCard from './Components/EventCard';
import { useEffect, useState } from 'react';
import Header from './Components/Header';
import Logger from '../../../Utils/Logger';
import axiosInstance from '../../../Utils/axiosInstance';
import { EVENTS_ENDPOINT } from '../../../Utils/Endpoints';
import moment from 'moment';
import Pagination from '../../Components/Pagination';

const Main = () => {
  const [eventList, setEventList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // Pagination
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchEvent();
  }, [currentPage]);

  const fetchEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENTS_ENDPOINT +
          `?event_start_from=${moment().unix()}&page=${currentPage}&per_page=${itemsPerPage}`
      );
      if (response.data.code === 200) {
        setEventList(response.data.data.data);
        setTotalItems(response.data.data.total);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />
            <div className="edgtf-content" style={{ marginTop: '-90px' }}>
              <div className="edgtf-content-inner">
                <div className="edgtf-full-width">
                  <div className="edgtf-full-width-inner pb-0 pt-0">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="wpb-content-wrapper">
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div
                                    className="hero-section"
                                    style={{
                                      backgroundImage:
                                        'url("../images/hero-background.jpg")',
                                    }}
                                  >
                                    <h3>Start with</h3>
                                    <h1>Partypass</h1>
                                    <h2 style={{ padding: '0 20px' }}>
                                      Feel the beat of the night
                                    </h2>
                                    <Link
                                      to="#"
                                      className="edgtf-btn d-none edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                                    >
                                      Expore Events
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {eventList?.length > 0 && (
                            <div className="vc_row wpb_row vc_row-fluid">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    {/* Events Listing START */}
                                    <div className="events-listing">
                                      <var className="edgtf-st-tagline">
                                        Upcoming Events{' '}
                                      </var>
                                      <div className="outer outer-new">
                                        {!isLoading &&
                                          eventList?.map((item, index) => (
                                            <EventCard
                                              event={item}
                                              imageUser={
                                                item?.banner?.fullUrl
                                                  ? item?.banner?.fullUrl
                                                  : '../images/thumbnail.png'
                                              }
                                              heading={item?.name}
                                              text={item?.startDateTimeFormat}
                                              key={index}
                                            />
                                          ))}
                                      </div>
                                      <div>
                                        {totalItems > 10 && (
                                          <Pagination
                                            itemsPerPage={itemsPerPage}
                                            totalItems={totalItems}
                                            currentPage={currentPage}
                                            onPageChange={handlePageChange}
                                          />
                                        )}
                                      </div>
                                    </div>

                                    {/* Events Listing END */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="edgtf-row-grid-section-wrapper edgtf-content-aligment-center">
                            <div className="edgtf-row-grid-section">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1522835334066">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="edgtf-section-title-holder  ">
                                        <div className="edgtf-st-inner">
                                          <var className="edgtf-st-tagline">
                                            Lead the fun.{' '}
                                          </var>
                                          <h1 className="edgtf-st-title">
                                            Find Your Event Now
                                            <span className="edgtf-st-title-dot">
                                              .
                                            </span>
                                          </h1>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '84px' }}
                                      >
                                        <span className="vc_empty_space_inner"></span>
                                      </div>
                                      <div className="edgtf-rf-holder edgtf-rf-light">
                                        <form
                                          className="edgtf-rf"
                                          target="_blank"
                                          name="edgtf-rf"
                                        >
                                          <div className="edgtf-rf-row clearfix">
                                            <div className="edgtf-rf-col-holder">
                                              <div className="edgtf-rf-field-holder clearfix">
                                                <select
                                                  name="partySize"
                                                  className="edgtf-ot-people"
                                                >
                                                  <option value="1">
                                                    1 Person
                                                  </option>
                                                  <option value="2">
                                                    2 People
                                                  </option>
                                                  <option value="3">
                                                    3 People
                                                  </option>
                                                  <option value="4">
                                                    4 People
                                                  </option>
                                                  <option value="5">
                                                    5 People
                                                  </option>
                                                </select>
                                                <span className="edgtf-rf-icon">
                                                  <span className="edgtf-rf-group"></span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="edgtf-rf-col-separator">
                                              <span>for</span>
                                            </div>
                                            <div className="edgtf-rf-col-holder">
                                              <div className="edgtf-rf-field-holder clearfix">
                                                <input
                                                  type="text"
                                                  defaultValue="10/07/2024"
                                                  className="edgtf-ot-date"
                                                  name="startDate"
                                                />
                                                <span className="edgtf-rf-icon">
                                                  <span className="edgtf-rf-calendar"></span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="edgtf-rf-col-separator">
                                              <span>at</span>
                                            </div>
                                            <div className="edgtf-rf-col-holder edgtf-rf-time-col">
                                              <div className="edgtf-rf-field-holder clearfix">
                                                <select
                                                  name="ResTime"
                                                  className="edgtf-ot-time"
                                                >
                                                  <option value="6:30am">
                                                    6:30 am
                                                  </option>
                                                  <option value="7:00am">
                                                    7:00 am
                                                  </option>
                                                  <option value="7:30am">
                                                    7:30 am
                                                  </option>
                                                  <option value="8:00am">
                                                    8:00 am
                                                  </option>
                                                  <option value="8:30am">
                                                    8:30 am
                                                  </option>
                                                </select>
                                                <span className="edgtf-rf-icon">
                                                  <span className="edgtf-rf-clock"></span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="edgtf-rf-col-holder edgtf-rf-btn-holder">
                                              <button
                                                type="button"
                                                className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                                              >
                                                <span className="edgtf-btn-text">
                                                  Search
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-middle vc_row-flex edgtf-content-aligment-left">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="edgtf-elements-holder edgtf-two-columns edgtf-responsive-mode-1024">
                                    <div className="edgtf-eh-item edgtf-horizontal-alignment-center">
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-3391">
                                          <div className="edgtf-single-image-holder">
                                            <div className="edgtf-si-inner in-ner ">
                                              <img
                                                className="attachment-full size-full"
                                                alt="c"
                                                // srcSet="images/h1-img-1.jpg 800w, images/h1-img-1-150x94.jpg 150w, images/h1-img-1-300x188.jpg 300w, images/h1-img-1-768x480.jpg 768w"
                                                // sizes="(max-width: 800px) 100vw, 800px"
                                                src="../images/seller.png"
                                                style={{
                                                  width: '100%',
                                                  height: '100%',
                                                  margin: '50px 0',
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="edgtf-eh-item    "
                                      data-item-classname="edgtf-eh-custom-2601"
                                      data-1280-1600="177px 57px 230px 0"
                                      data-1024-1280="177px 13px 230px 0 "
                                      data-768-1024="0 247px 230px 120px"
                                      data-680-768="0 55px 230px 80px"
                                      data-680="80px 15px 230px 15px"
                                    >
                                      <div className="edgtf-eh-item-inner edgtf-in pb-add">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-2601">
                                          <div className="edgtf-section-title-holder ">
                                            <div className="edgtf-st-inner ">
                                              <var className="edgtf-st-tagline">
                                                Be at Ticket Seller.{' '}
                                              </var>
                                              <h1 className="edgtf-st-title">
                                                Become A Seller
                                                <span className="edgtf-st-title-dot">
                                                  .
                                                </span>
                                              </h1>
                                              <p
                                                className="edgtf-st-text"
                                                style={{
                                                  color: '#ffffff',
                                                  marginTop: '23px',
                                                }}
                                              >
                                                Take Control of Your Event, Sell
                                                Tickets with Ease Are you an
                                                event organizer looking to reach
                                                a larger audience and streamline
                                                ticket sales? Join Party Pass
                                                and take advantage of our
                                                easy-to-use platform to manage,
                                                promote, and sell tickets for
                                                your events. Whether you&lsquo;
                                                react/no-unescaped-entities re
                                                hosting a conference, concert,
                                                festival, or any other type of
                                                gathering, our platform offers
                                                everything you need to sell
                                                tickets and manage your
                                                attendees.
                                              </p>
                                            </div>
                                          </div>
                                          <div
                                            className="vc_empty_space"
                                            style={{ height: '21px' }}
                                          >
                                            <span className="vc_empty_space_inner"></span>
                                          </div>
                                          <Link
                                            to={
                                              process.env.REACT_APP_SELLER_URL +
                                              '/login'
                                            }
                                            target="_blank"
                                            style={{
                                              color: '#ffffff',
                                              borderColor: '#ffffff',
                                            }}
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-outline edgtf-btn-orange-black edgtf-btn-custom-hover-bg edgtf-btn-custom-border-hover edgtf-btn-custom-hover-color"
                                            data-hover-color="#000000"
                                            data-hover-bg-color="#ffffff"
                                            data-hover-border-color="#ffffff"
                                          >
                                            <span className="edgtf-btn-text">
                                              Seller Login
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            data-parallax-bg-image="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/h1-video-img.jpg"
                            className="vc_row wpb_row vc_row-fluid vc_row-o-full-height vc_row-o-columns-middle vc_row-o-content-middle vc_row-flex edgtf-parallax-row-holder"
                            style={{
                              backgroundImage:
                                'url(https://offbeat.qodeinteractive.com/wp-content/uploads/2018/03/h1-video-img.jpg)',
                              backgroundPosition: '50% 46px',
                            }}
                          >
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="edgtf-elements-holder  edgtf-eh-full-height edgtf-one-column  edgtf-responsive-mode-768 edgtf-one-column-alignment-center">
                                    <div className="edgtf-eh-item   edgtf-horizontal-alignment-center ">
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content">
                                          <div className="edgtf-video-button-holder ">
                                            <Link
                                              className="edgtf-video-button-play"
                                              to="#"
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop"
                                            >
                                              <span className="edgtf-video-button-play-inner">
                                                <span className="edgtf-video-button-circle">
                                                  <span className="edgtf-video-button-triangle"></span>
                                                </span>
                                              </span>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="edgtf-row-grid-section-wrapper edgtf-content-aligment-left"
                            style={{ backgroundColor: '#000000' }}
                          >
                            <div className="edgtf-row-grid-section d-none">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1520843122533">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div
                                        className="edgtf-section-title-holder  "
                                        style={{ textAlign: 'center' }}
                                      >
                                        <div className="edgtf-st-inner">
                                          <var className="edgtf-st-tagline">
                                            Our finnest{' '}
                                          </var>
                                          <h2 className="edgtf-st-title">
                                            CRAFT BEER Selection
                                            <span className="edgtf-st-title-dot">
                                              .
                                            </span>
                                          </h2>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '60px' }}
                                      >
                                        <span className="vc_empty_space_inner"></span>
                                      </div>
                                      <div className="edgtf-elements-holder   edgtf-two-columns  edgtf-responsive-mode-1024 edgtf-ui">
                                        <div
                                          className="edgtf-eh-item    "
                                          data-item-classname="edgtf-eh-custom-8340"
                                          data-1280-1600="0 0 0 116px"
                                          data-1024-1280="0 0 0 116px"
                                          data-768-1024="0 0 0 0"
                                          data-680-768="0 0 0 0"
                                          data-680="0 0 0 0"
                                        >
                                          <div className="edgtf-eh-item-inner">
                                            <div
                                              className="edgtf-eh-item-content edgtf-eh-custom-8340"
                                              // style={{ padding: '0 0 0 157px' }}
                                            >
                                              <div className="offbeat-pricing-list clearfix offbeat-pli-light offbeat-pli-normal-space">
                                                <div className="offbeat-pl-wrapper">
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-1.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Summit Craft
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$2.95</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Saga IPA, Oatmeal
                                                            Stout, High Sticke
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-3.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Barley Garden
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>Finest IPA</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-5.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Brooklyn{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.15</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Defender IPA,
                                                            Scorcher Ipa,
                                                            Sorachi
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-7.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Hobgoblin{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.45</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Hobgoblin Gold,
                                                            Hobgoblin Ipa
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-9.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Bluemoon{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Belgian White, Mango
                                                            Wheat
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-11.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Brewdog{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.80</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Punk IPA, 5AM Saint,
                                                            Elvis Juice
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="edgtf-eh-item    "
                                          data-item-classname="edgtf-eh-custom-5202"
                                          data-1280-1600="0 93px 0 0px"
                                          data-768-1024="0 0 0 0"
                                          data-680-768="0 0 0 0"
                                          data-680="0 0 0 0"
                                        >
                                          <div className="edgtf-eh-item-inner">
                                            <div
                                              className="edgtf-eh-item-content edgtf-eh-custom-5202"
                                              // style={{
                                              //   padding: '0 144px 0 0px',
                                              // }}
                                            >
                                              <div className="offbeat-pricing-list clearfix offbeat-pli-light offbeat-pli-normal-space">
                                                <div className="offbeat-pl-wrapper">
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-2.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Weisse{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$2.95</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Saga IPA, Oatmeal
                                                            Stout, High Sticke
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-4.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Bomber{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>Finest IPA</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-6.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Hill Brothers
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.15</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Defender IPA,
                                                            Scorcher Ipa,
                                                            Sorachi
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-8.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Paulaner{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$3.45</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Hobgoblin Gold,
                                                            Hobgoblin Ipa
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-10.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Culture On Tap
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.65</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Belgian White, Mango
                                                            Wheat
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="offbeat-pricing-list-item clearfix offbeat-pli-with-image">
                                                    <div className="offbeat-pli-image-holder">
                                                      <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width="69"
                                                        height="51"
                                                        className="attachment-thumbnail size-thumbnail"
                                                        alt="s"
                                                        src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/02/h1-custom-icon-img-12.png"
                                                      />
                                                    </div>
                                                    <div className="offbeat-pli-content clearfix">
                                                      <div className="offbeat-pli-title-holder">
                                                        <h4 className="offbeat-pli-title entry-title">
                                                          Knocker{' '}
                                                        </h4>
                                                        <div className="offbeat-pli-dots"></div>
                                                        <div className="offbeat-pli-price-holder">
                                                          <span className="offbeat-pli-price">
                                                            <span>$4.80</span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="offbeat-pli-bottom-content">
                                                        <div className="offbeat-pli-desc clearfix">
                                                          <p>
                                                            Punk IPA, 5AM Saint,
                                                            Elvis Juice
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '16px' }}
                                      >
                                        <span className="vc_empty_space_inner"></span>
                                      </div>
                                      <div className="vc_row wpb_row vc_inner vc_row-fluid edgtf-content-aligment-center">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                          <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                              <Link
                                                to="#"
                                                target="_self"
                                                style={{
                                                  color: '#ffffff',
                                                  backgroundColor: '#f45f0b',
                                                  textTransform: 'uppercase',
                                                }}
                                                className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                                              >
                                                <span className="edgtf-btn-text">
                                                  View Menu
                                                </span>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="vc_row wpb_row vc_row-fluid vc_custom_1523350595066 ss-pan-dding vc_row-o-full-height vc_row-o-columns-top vc_row-flex"
                            style={{
                              backgroundImage: 'url("../images/h1-img-2.jpg")',
                            }}
                          >
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="offbeat-event-list-holder offbeat-event-list-simple edgtf-light-skin edgtf-el-no-columns edgtf-normal-space">
                                    <div className="offbeat-event-list-holder-inner">
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 1{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 2{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 3{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="edgtf-el-item">
                                        <Link
                                          className="edgtf-el-item-link-outer"
                                          to="#"
                                        ></Link>
                                        <div className="edgtf-el-item-title-holder">
                                          <h3 className="edgtf-el-item-title">
                                            Amsterdam’s Jazz Bands September
                                            Battle – Day 4{' '}
                                          </h3>
                                        </div>
                                        <div className="edgtf-el-item-link-holder">
                                          <Link
                                            to="#"
                                            target="_self"
                                            className="edgtf-btn edgtf-btn-medium edgtf-btn-simple edgtf-btn-orange-black edgtf-btn-icon edgtf-el-item-link"
                                          >
                                            <span className="edgtf-btn-text">
                                              Learn More
                                            </span>{' '}
                                            <span
                                              aria-hidden="true"
                                              className="edgtf-icon-font-elegant arrow_triangle-right "
                                            ></span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-none vc_row wpb_row vc_row-fluid vc_custom_1520262019848 vc_row-has-fill vc_row-o-content-middle vc_row-flex edgtf-content-aligment-center">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="edgtf-elements-holder edgtf-two-columns edgtf-responsive-mode-1024 ">
                                    <div
                                      className="edgtf-eh-item    "
                                      data-item-classname="edgtf-eh-custom-9820"
                                      data-1280-1600="42px 0px 75px 93px "
                                      data-1024-1280="42px 0 75px 65px"
                                      data-768-1024="42px 0px 86px 0px"
                                      data-680-768="42px 0px 86px 0px"
                                      data-680="42px 10px 86px 10px"
                                    >
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-9820 edgtf-eh-2">
                                          <div className="wpb_text_column wpb_content_element">
                                            <div className="wpb_wrapper">
                                              <h3>
                                                Don’t Miss Out Fun. Subscribe.
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="edgtf-eh-item"
                                      data-item-classname="edgtf-eh-custom-8405"
                                      data-1280-1600="0 118px 0 57px "
                                      data-1024-1280="0 95px 0 60px "
                                      data-768-1024="0 172px 54px 172px "
                                      data-680-768="0 45px 54px 46px"
                                      data-680="0 10px 54px 10px"
                                    >
                                      <div className="edgtf-eh-item-inner">
                                        <div className="edgtf-eh-item-content edgtf-eh-custom-8405 edgtf-eh-3">
                                          <div
                                            className="wpcf7 no-js"
                                            id="wpcf7-f5-p21-o1"
                                            lang="en-US"
                                            dir="ltr"
                                          >
                                            <div className="screen-reader-response">
                                              <p
                                                role="status"
                                                aria-live="polite"
                                                aria-atomic="true"
                                              ></p>
                                              <ul></ul>
                                            </div>
                                            <form
                                              className="wpcf7-form init cf7_custom_style_1 demo"
                                              aria-label="Contact form"
                                              noValidate="novalidate"
                                              data-status="init"
                                            >
                                              <div className="edgtf-two-columns-form-without-space">
                                                <div className="edgtf-column-left">
                                                  <p>
                                                    <span
                                                      className="wpcf7-form-control-wrap"
                                                      data-name="email"
                                                    >
                                                      <input
                                                        size="40"
                                                        maxLength="80"
                                                        className="wpcf7-form-control wpcf7-email wpcf7-text wpcf7-validates-as-email"
                                                        aria-invalid="false"
                                                        placeholder="enter your email"
                                                        defaultValue=""
                                                        type="email"
                                                        name="email"
                                                      />
                                                    </span>
                                                  </p>
                                                </div>
                                                <div className="edgtf-column-right">
                                                  <p>
                                                    <input
                                                      className="wpcf7-form-control wpcf7-submit has-spinner"
                                                      type="button"
                                                      defaultValue="SUBSCRIBE"
                                                    />
                                                  </p>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner vc_custom_1519635204890">
                                <div className="wpb_wrapper">
                                  <div className="wpb_widgetised_column wpb_content_element">
                                    <div className="wpb_wrapper">
                                      <div className="widget widget_edgtf_instagram_widget"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="edgtf-page-footer">
              <div
                className="edgtf-footer-top-holder border-dis"
                style={{ borderTop: '1px solid #000' }}
              >
                <div className="edgtf-footer-top-inner edgtf-grid pb-3 pt-lg-5 pt-3 ">
                  <div className="edgtf-grid-row edgtf-footer-top-alignment-left">
                    <div className="edgtf-column-content edgtf-grid-col-7 col-12 mb-0">
                      <div
                        id="text-3"
                        className="widget edgtf-footer-column-3 widget_text"
                      >
                        <h5 className="edgtf-widget-title mb-0">CONTACT US</h5>
                        <div className="con-text">
                          <div className="textwidget">
                            <p className="first-p">
                              <Link to="mailto:contact@partypass.com">
                                contact@partypass.com
                              </Link>
                              <br />
                              <Link to="tel:1-562-867-5309">
                                1-562-867-5309
                              </Link>
                            </p>
                          </div>
                          <div
                            id="text-11"
                            className="widget edgtf-footer-column-3 widget_text"
                          >
                            <div className="textwidget">
                              <p className="last-p">
                                <Link to="https://www.google.rs/maps/place/Charging+Bull/@40.7055242,-74.0133806,20z/data=!4m5!3m4!1s0x0:0xc7db393ae1eff521!8m2!3d40.7055537!4d-74.0134436">
                                  Broadway &amp; Morris St, New York
                                </Link>
                                <br />
                                <Link to="https://www.google.rs/maps/place/Autoklub+Vsetin+Czechia/@28.0903625,-80.5700215,17z/data=!3m1!4b1!4m5!3m4!1s0x88de116ac5f97e1b:0xbcd6048a3d229c61!8m2!3d28.0903625!4d-80.5678328">
                                  123 6th St. Melbourne, FL
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edgtf-column-content edgtf-grid-col-5 col-12 mt-0">
                      <div
                        id="custom_html-2"
                        className="widget_text widget edgtf-footer-column-4 widget_custom_html"
                      >
                        <h5 className="edgtf-widget-title mb-0">FOLLOW US</h5>
                        <div className="textwidget custom-html-widget">
                          <p className="mb-0 text-white">
                            For all the latest news and updates, follow us on
                          </p>
                        </div>
                        <div className="widget edgtf-social-icons-group-widget">
                          {' '}
                          <Link
                            className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                            style={{ margin: '6px 8px 0 0px' }}
                            to="https://www.instagram.com/qodeinteractive/"
                            target="_blank"
                          >
                            <span className="edgtf-social-icon-widget social_instagram_circle"></span>{' '}
                          </Link>
                          <Link
                            className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                            style={{ margin: '6px 8px 0 8px' }}
                            to="https://www.facebook.com/QodeInteractive/"
                            target="_blank"
                          >
                            <span className="edgtf-social-icon-widget social_facebook_circle"></span>{' '}
                          </Link>
                          <Link
                            className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                            style={{ margin: '6px 8px 0 8px' }}
                            to="https://plus.google.com/"
                            target="_blank"
                          >
                            <span className="edgtf-social-icon-widget social_googleplus_circle"></span>{' '}
                          </Link>
                          <Link
                            className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                            style={{ margin: '6px 8px 0 8px' }}
                            to="https://twitter.com/QodeInteractive"
                            target="_blank"
                          >
                            <span className="edgtf-social-icon-widget social_twitter_circle"></span>{' '}
                          </Link>
                          <Link
                            className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                            style={{ margin: '6px 8px 0 8px' }}
                            to="https://www.youtube.com/QodeInteractiveVideos"
                            target="_blank"
                          >
                            <span className="edgtf-social-icon-widget social_youtube_circle"></span>{' '}
                          </Link>
                          <Link
                            className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                            style={{ margin: '6px 8px 0 8px' }}
                            to="https://vimeo.com/"
                            target="_blank"
                          >
                            <span className="edgtf-social-icon-widget social_vimeo_circle"></span>{' '}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edgtf-footer-bottom-holder ">
                <div className="edgtf-footer-bottom-inner edgtf-grid">
                  <div className="edgtf-grid-row content-center">
                    <div className="edgtf-grid-col-7 ps-2">
                      <div
                        id="media_image-2"
                        className="widget edgtf-footer-bottom-column-1 widget_media_image text-lg-start text-center"
                      >
                        <div className="edgtf-logo-wrapper">
                          <Link to="#">
                            <img
                              width="40"
                              height="40"
                              alt="logo"
                              src="../images/emblem.png"
                            />
                            PartyPass
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="edgtf-grid-col-5 d-none">
                      <div className="widget edgtf-social-icons-group-widget text-align-center">
                        {' '}
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://www.instagram.com/qodeinteractive/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_instagram_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://www.facebook.com/QodeInteractive/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_facebook_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://plus.google.com/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_googleplus_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://twitter.com/QodeInteractive"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_twitter_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://www.youtube.com/QodeInteractiveVideos"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_youtube_circle"></span>{' '}
                        </Link>
                        <Link
                          className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                          style={{ margin: '6px 8px 0 8px' }}
                          to="https://vimeo.com/"
                          target="_blank"
                        >
                          <span className="edgtf-social-icon-widget social_vimeo_circle"></span>{' '}
                        </Link>
                      </div>
                    </div>
                    <div className="edgtf-grid-col-5 ">
                      <div className="widget edgtf-separator-widget">
                        <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                          <div
                            className="edgtf-separator"
                            style={{
                              borderColor: ' rgba(255, 255, 255, 0)',
                              borderStyle: 'solid',
                              marginTop: '-8px',
                            }}
                          ></div>
                        </div>
                      </div>
                      <div
                        id="text-6"
                        className="widget edgtf-footer-bottom-column-3 widget_text"
                      >
                        <div className="textwidget text-lg-start text-center">
                          <p className="text-white">
                            © 2024{' '}
                            <Link
                              to="https://qodeinteractive.com/"
                              target="_blank"
                              rel="noopener"
                            >
                              PartyPass
                            </Link>
                            , All Rights Reserved
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>

      <div>
        <div
          className="modal fade"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          id="staticBackdrop"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ paddingRight: '0' }}
        >
          <div
            className="modal-dialog modal-lg"
            style={{ backgroundColor: 'transparent' }}
          >
            <div
              className="modal-content"
              style={{ backgroundColor: 'transparent' }}
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body p-0">
                <div className="embed-container">
                  <iframe
                    src="https://player.vimeo.com/video/66140585"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="modal-footer d-none">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
