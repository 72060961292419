import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Logger from '../../../../Utils/Logger';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  CART_CLEAR_ENDPOINT,
  CART_LIST_ENDPOINT,
  CART_REMOVE_ENDPOINT,
  CHECKOUT_PROCEED_ENDPOINT,
  PROMO_CODE_APPLY_ENDPOINT,
  PROMO_CODE_REMOVE_ENDPOINT,
} from '../../../../Utils/Endpoints';
import Empty from '../Components/Empty';
import Toast from '../../../../Utils/Toast';
import {
  clearItem,
  getLoginCustomer,
  getObject,
  saveObject,
} from '../../../../Utils/LocalStorage';

const Cart = () => {
  const [cartList, setCartList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [isClearLoading, setIsClearLoading] = useState(false);
  const [isProceedLoading, setIsProceedLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);
  const [appliedSuccess, setAppliedSuccess] = useState(false);
  const user = getLoginCustomer();

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchCart();
    }
  }, []);

  const fetchCart = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(CART_LIST_ENDPOINT);
      if (response.data.code === 200) {
        if (response?.data?.data?.cart !== 'Your cart is empty.') {
          setCartList(response.data.data);

          if (response?.data?.data?.cart?.promo_codes?.length > 0) {
            setPromoCode(response?.data?.data?.cart?.promo_codes[0]);
            setAppliedSuccess(true);
          }
        } else {
          setCartList({});
          clearItem('proceedCheckoutData');
        }
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProceedToCheckout = async (e) => {
    e.preventDefault();
    setIsProceedLoading(true);
    try {
      const orderData = getObject('proceedCheckoutData');
      let requestObject = {
        notes: '',
        order_id: orderData ? orderData?.id : null,
      };
      const response = await axiosInstance.post(
        CHECKOUT_PROCEED_ENDPOINT,
        requestObject
      );
      if (response.data.code === 200) {
        saveObject('proceedCheckoutData', response.data.data);
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
        navigate('/check-out');
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsProceedLoading(false);
    }
  };

  const handleRemove = async (e, key) => {
    e.preventDefault();
    try {
      setIsRemoveLoading(true);
      const requestObject = {
        key: key,
      };
      const response = await axiosInstance.post(
        CART_REMOVE_ENDPOINT,
        requestObject
      );
      if (response.status === 204) {
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      await fetchCart();
      setIsRemoveLoading(false);
    }
  };

  const handleClearCart = async (e) => {
    e.preventDefault();
    try {
      setIsClearLoading(true);
      const response = await axiosInstance.delete(CART_CLEAR_ENDPOINT);
      if (response.status === 204) {
        clearItem('proceedCheckoutData');
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      await fetchCart();
      setIsClearLoading(false);
    }
  };

  const handleApplyPromoCode = async (e) => {
    e.preventDefault();
    if (promoCode) {
      try {
        setIsLoadingCoupon(true);
        const requestObject = {
          code: promoCode,
        };
        const response = await axiosInstance.post(
          PROMO_CODE_APPLY_ENDPOINT,
          requestObject
        );
        if (response.data.code === 200) {
          setAppliedSuccess(true);
          Toast.success(response.data.message, {
            autoClose: 2000,
          });
          await fetchCart();
        }
      } catch (error) {
        setGeneralError(error.response?.data?.message);
      } finally {
        setIsLoadingCoupon(false);
      }
    }
  };

  const handleRemovePromoCode = async (e) => {
    e.preventDefault();
    if (promoCode) {
      try {
        setIsLoadingCoupon(true);
        const requestObject = {
          code: promoCode,
        };
        const response = await axiosInstance.post(
          PROMO_CODE_REMOVE_ENDPOINT,
          requestObject
        );
        if (response.data.code === 200) {
          setPromoCode('');
          setAppliedSuccess(false);
          Toast.success(response.data.message, {
            autoClose: 2000,
          });
          await fetchCart();
        }
      } catch (error) {
        setGeneralError(error.response?.data?.message);
      } finally {
        setIsLoadingCoupon(false);
      }
    }
  };

  return (
    <>
      <div className="edgtf-woocommerce-page edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />

            {/* html new add start */}

            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix pt-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <h2 className="text-white">Cart Total</h2>
                      <Link
                        to="/"
                        style={{
                          color: '#f45f0b',
                          textDecoration: 'underline',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <ion-icon name="arrow-back-outline"></ion-icon>
                        {''}
                        Go to Home
                      </Link>
                    </div>

                    {!isLoading &&
                      cartList &&
                      Object.keys(cartList)?.length > 0 && (
                        <div className="edgtf-grid-row">
                          <div className="text-align-right mb-2">
                            <button
                              className="btn-danger edgtf-btn clear"
                              disabled={isClearLoading}
                              onClick={handleClearCart}
                            >
                              {isClearLoading ? 'Loading...' : 'Clear Cart'}
                            </button>
                          </div>
                          <div className="edgtf-page-content-holder edgtf-grid-col-12">
                            <div className="woocommerce">
                              <div className="woocommerce-notices-wrapper"></div>
                              <form
                                className="woocommerce-cart-form"
                                action="#"
                                method="post"
                              >
                                <div className="table-responsive">
                                  <table
                                    className="shop_table shop_table_responsive cart woocommerce-cart-form__contents"
                                    cellSpacing="0"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="product-remove px-2">
                                          <span className="screen-reader-text">
                                            Remove item
                                          </span>
                                        </th>

                                        <th className="product-name px-2 table-ww">
                                          Product
                                        </th>
                                        <th className="product-quantity text-center px-2">
                                          Quantity
                                        </th>
                                        <th className="product-price px-5 text-center">
                                          Price
                                        </th>

                                        <th className="product-subtotal text-end pe-2 ">
                                          Subtotal
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.entries(
                                        cartList?.cart?.items
                                      ).map(
                                        ([
                                          key,
                                          { item_totals, model, quantity },
                                        ]) => (
                                          <tr
                                            className="woocommerce-cart-form__cart-item cart_item"
                                            key={key}
                                          >
                                            <td className="product-remove">
                                              <button
                                                className="cross-icon"
                                                onClick={(e) =>
                                                  handleRemove(e, key)
                                                }
                                                disabled={isRemoveLoading}
                                                style={{
                                                  color: '#f45f0b',
                                                }}
                                              >
                                                <ion-icon name="close"></ion-icon>
                                              </button>
                                            </td>

                                            <td
                                              className="product-name px-3 px-md-2"
                                              data-title="Product"
                                            >
                                              {model?.event?.organization?.name}
                                              <p className="m-0">
                                                {model?.event?.name} ({' '}
                                                {model?.name} )
                                              </p>
                                            </td>

                                            <td
                                              className="product-quantity text-center"
                                              data-title="Quantity"
                                            >
                                              <div className="edgtf-quantity-buttons quantity">
                                                <label
                                                  className="screen-reader-text"
                                                  htmlFor="quantity_6721d5cb47a3c"
                                                >
                                                  {model?.name}
                                                </label>

                                                <span className="text-white">
                                                  <bdi>{quantity}</bdi>
                                                </span>
                                              </div>
                                            </td>
                                            <td
                                              className="product-price px-5 text-center"
                                              data-title="Price"
                                            >
                                              <span className="woocommerce-Price-amount amount  text-white">
                                                <bdi>
                                                  <span className="woocommerce-Price-currencySymbol">
                                                    {model?.priceFormat}
                                                  </span>
                                                </bdi>
                                              </span>
                                            </td>

                                            <td
                                              className="product-subtotal text-end pe-2"
                                              data-title="Subtotal"
                                            >
                                              <span className="woocommerce-Price-amount amount">
                                                <bdi>
                                                  <span className="woocommerce-Price-currencySymbol text-white">
                                                    {
                                                      item_totals?.base_total_format
                                                    }
                                                    <br />
                                                    {model?.fee_to_customer ===
                                                      0 &&
                                                      '( Service Fee Included )'}
                                                  </span>
                                                </bdi>
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      )}

                                      <tr className="">
                                        <td colSpan="6" className="actions">
                                          <div className="coupon">
                                            <label
                                              htmlFor="coupon_code"
                                              className="screen-reader-text"
                                            >
                                              Coupon:
                                            </label>
                                            <input
                                              type="text"
                                              name="coupon_code"
                                              className="input-text text-white"
                                              id="coupon_code"
                                              placeholder="Promo Code"
                                              style={{ padding: '12px 18px' }}
                                              onChange={(e) => {
                                                setPromoCode(e.target.value);
                                              }}
                                              disabled={appliedSuccess}
                                              value={promoCode}
                                            />
                                            {!appliedSuccess && (
                                              <button
                                                type="button"
                                                name="apply_coupon"
                                                className={`edgtf-btn edgtf-btn-large edgtf-btn-solid edgtf-btn-orange-white edgtf-el-item-link
                                          ${appliedSuccess ? 'disabled' : ''}`}
                                                onClick={handleApplyPromoCode}
                                                disabled={appliedSuccess}
                                              >
                                                {isLoadingCoupon
                                                  ? 'Loading...'
                                                  : ' Apply coupon'}
                                              </button>
                                            )}
                                            {/* {error && (
                                            <div className="text-danger m-1">
                                              {error}
                                            </div>
                                          )} */}
                                            {appliedSuccess && (
                                              <>
                                                <button
                                                  type="button"
                                                  name="apply_coupon"
                                                  className={`edgtf-btn edgtf-btn-large edgtf-btn-solid edgtf-btn-orange-white edgtf-el-item-link `}
                                                  onClick={
                                                    handleRemovePromoCode
                                                  }
                                                >
                                                  {isLoadingCoupon
                                                    ? 'Loading...'
                                                    : 'Remove'}
                                                </button>

                                                <p className="text-success">
                                                  Coupon code applied
                                                  successfully.
                                                  <u className="remove-coupon ms-5 "></u>
                                                </p>
                                              </>
                                            )}
                                          </div>
                                          {/* <button
                                   type="submit"
                                   className="button"
                                   name="update_cart"
                                   value="Update cart"
                                   disabled=""
                                 >
                                   Update cart
                                 </button> */}
                                          <input
                                            type="hidden"
                                            id="woocommerce-cart-nonce"
                                            name="woocommerce-cart-nonce"
                                            value="a93b735570"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wp_http_referer"
                                            value="/cart/"
                                          />{' '}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </form>

                              <div className="cart-collaterals">
                                <div className="cart_totals calculated_shipping ">
                                  <h2 className="text-white">Cart Total</h2>

                                  <table
                                    cellSpacing="0"
                                    className="shop_table shop_table_responsive text-white"
                                  >
                                    <tbody>
                                      <tr className="cart-subtotal">
                                        <th>
                                          Subtotal{' '}
                                          {cartList?.cart
                                            ?.fee_to_subtotal_text && (
                                            <>
                                              ({' '}
                                              {
                                                cartList?.cart
                                                  ?.fee_to_subtotal_text
                                              }{' '}
                                              )
                                            </>
                                          )}
                                        </th>
                                        <td data-title="Subtotal">
                                          <span className="woocommerce-Price-amount amount">
                                            <bdi>
                                              <span className="woocommerce-Price-currencySymbol">
                                                {
                                                  cartList?.cart?.totals
                                                    ?.base_total_format
                                                }
                                              </span>
                                            </bdi>
                                          </span>
                                        </td>
                                      </tr>

                                      <tr className="order-total">
                                        <th>Fee </th>
                                        <td data-title="-fee">
                                          <strong>
                                            <span className="woocommerce-Price-amount amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                  {
                                                    cartList?.cart?.totals
                                                      ?.total_fee_to_show_format
                                                  }
                                                </span>
                                              </bdi>
                                            </span>
                                          </strong>{' '}
                                        </td>
                                      </tr>

                                      {cartList?.cart?.totals?.discount !==
                                        0 && (
                                        <tr className="order-total">
                                          <th>Discount</th>
                                          <td data-title="discount">
                                            <strong>
                                              <span className="woocommerce-Price-amount amount">
                                                <bdi>
                                                  <span className="woocommerce-Price-currencySymbol">
                                                    {
                                                      cartList?.cart?.totals
                                                        ?.discount_format
                                                    }
                                                  </span>
                                                </bdi>
                                              </span>
                                            </strong>
                                          </td>
                                        </tr>
                                      )}
                                      <tr className="order-total">
                                        <th>Total</th>
                                        <td data-title="Total">
                                          <strong>
                                            <span className="woocommerce-Price-amount amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol">
                                                  {
                                                    cartList?.cart?.totals
                                                      ?.total_after_discount_format
                                                  }
                                                </span>
                                              </bdi>
                                            </span>
                                          </strong>{' '}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <div className="wc-proceed-to-checkout">
                                    <Link
                                      type="button"
                                      disabled={isProceedLoading}
                                      onClick={handleProceedToCheckout}
                                      className="checkout-button button alt wc-forward"
                                    >
                                      {isProceedLoading
                                        ? 'Loading checkout...'
                                        : 'Proceed to checkout'}
                                    </Link>
                                  </div>

                                  {generalError && (
                                    <div className="error">{generalError}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {!isLoading &&
                      cartList &&
                      Object.keys(cartList)?.length === 0 && <Empty />}
                  </div>
                </div>
              </div>
            </div>
            {/* html new add end */}

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
