import { useEffect, useRef, useState } from 'react';
// import './ProfileUpdate.css';
import * as Yup from 'yup';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  REMOVE_ENDPOINT,
  UPDATE_PROFILE_ENDPOINT,
} from '../../../../Utils/Endpoints';
import {
  getLoginCustomer,
  setLoginCustomer,
} from '../../../../Utils/LocalStorage';
import Logger from '../../../../Utils/Logger';
import { uploadFile } from '../../../../Utils/upload';
import { USER_PROFILE_PICTURE_TYPE } from '../../../../Utils/UploadType';
import { USER_CLASS } from '../../../../Utils/ModelClasses';
import Toast from '../../../../Utils/Toast';
import Header from '../../Home/Components/Header';
import Footer from '../../Home/Components/Footer';
// Validation schema
const ProfileUpdateSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  bio: Yup.string().required('About is required.'),
  lastName: Yup.string().required('Last Name is required'),
  password: Yup.string().test('isChangePassword', 'Required', function (value) {
    if (this.parent.isChangePassword) {
      if (value === '') {
        return this.createError({ message: 'Password is required.' });
      }
      return true;
    }
    return true;
  }),
  confirmPassword: Yup.string().test(
    'isChangePassword',
    'Required',
    function (value) {
      if (this.parent.isChangePassword) {
        if (value === '') {
          return this.createError({ message: 'Confirm Password is required.' });
        }
        if (value !== this.parent.password) {
          return this.createError({ message: 'Passwords must match' });
        }
        return true;
      }
      return true;
    }
  ),
});

const ProfileUpdate = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [dob, setDob] = useState('');
  const [bio, setBio] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const customer = getLoginCustomer();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Define allowed file types
      const allowedFileTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/tiff',
      ];

      // Check if the selected file type is allowed
      if (!allowedFileTypes.includes(selectedFile.type)) {
        Toast.error(
          'Invalid file type selected. Please upload a valid image file.',
          {
            autoClose: 2000,
          }
        );
        return;
      }

      setIsImageLoading(true);
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      try {
        const imageData = await uploadFile(
          USER_CLASS,
          customer?.id,
          USER_PROFILE_PICTURE_TYPE,
          selectedFile
        );
        setPreview(imageData?.data?.data?.fullUrl);
        Toast.success(imageData.data.message, {
          autoClose: 2000,
        });
        await handleSubmit(event);
      } catch (error) {
        setPreview(null);
        Logger.error('Error uploading file:', error);
      } finally {
        setIsImageLoading(false);
      }
    }
  };

  const validate = () => {
    try {
      setGeneralError('');
      ProfileUpdateSchema.validateSync(
        {
          firstName,
          lastName,
          password,
          confirmPassword,
          isChangePassword,
          bio,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      setTimeout(() => {
        setErrors({});
      }, 3000);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);
    try {
      let requestObject = {};
      if (isChangePassword) {
        requestObject = {
          first_name: firstName,
          last_name: lastName,
          password: password,
          password_confirmation: confirmPassword,
          username: userName,
          phone_number: phoneNumber,
          dob: dob,
          gender: gender,
          bio: bio ? bio : '',
        };
      } else {
        requestObject = {
          first_name: firstName,
          last_name: lastName,
          username: userName,
          phone_number: phoneNumber,
          dob: dob,
          gender: gender,
          bio: bio ? bio : '',
        };
      }
      const response = await axiosInstance.patch(
        UPDATE_PROFILE_ENDPOINT,
        requestObject
      );
      if (response.data.code === 200) {
        await setLoginCustomer(response.data.data);
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (customer && customer.status !== 'PENDING') {
      setFirstName(customer.first_name);
      setLastName(customer.last_name);
      setUserName(customer.username);
      setGender(customer.gender);
      setBio(customer.bio);
      setDob(customer.dob);
      setEmail(customer.email);
      setPhoneNumber(customer.phone_number);
      setPreview(customer.profile_picture?.fullUrl);
    } else {
      setEmail(customer.email);
      setUserName(customer.username);
      setGender(customer.gender);
      setIsChangePassword(true);
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleClosePassword = () => {
    setIsChangePassword(!isChangePassword);
    setConfirmPassword('');
    setPassword('');
    setShowConfirmPassword(false);
    setShowPassword(false);
    setErrors({});
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      setIsImageLoading(true);
      const response = await axiosInstance.delete(REMOVE_ENDPOINT + id);
      if (response.status === 204) {
        setPreview(null);
        setFile(null);
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
        await handleSubmit(e);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsImageLoading(false);
    }
  };

  return (
    <>
      <div className="edgtf-header-standard  edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />
            {/* new chat start */}
            <div className="edgtf-content" style={{ marginTop: '-98px' }}>
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix all-pt">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="wpb-content-wrapper">
                          <div className="edgtf-row-grid-section-wrapper">
                            <div className="edgtf-row-grid-section">
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1634043061572">
                                <div className="wpb_column vc_column_container vc_col-sm-11 vc_col-lg-offset-0 vc_col-lg-6 vc_col-md-offset-0 vc_col-md-9 vc_col-sm-offset-0">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div className="edgtf-section-title-holder">
                                        <div className="edgtf-st-inner">
                                          <var
                                            className="edgtf-st-tagline"
                                            style={{ marginBottom: '-7px' }}
                                          >
                                            Your very own
                                          </var>
                                          <h3 className="edgtf-st-title">
                                            Edit Profile
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="edgtf-row-grid-section-wrapper">
                            <div className="edgtf-row-grid-section">
                              {/* error start */}
                              <div className="mt-2">
                                {Object.keys(errors).length > 0 && (
                                  <div className="error">
                                    Please ensure that all fields are filled
                                    out.
                                  </div>
                                )}
                              </div>
                              {/* error end */}
                              <div className="vc_row wpb_row vc_row-fluid vc_custom_1522241891078 vc_row-o-content-top vc_row-flex">
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-0 vc_col-lg-8 vc_col-md-offset-0 vc_col-md-12 vc_col-sm-offset-0">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      <div
                                        className="wpcf7 js"
                                        id="wpcf7-f183-p1492-o1"
                                        lang="en-US"
                                        dir="ltr"
                                      >
                                        <div className="screen-reader-response">
                                          <p
                                            role="status"
                                            aria-live="polite"
                                            aria-atomic="true"
                                          />
                                          <ul />
                                        </div>

                                        <form
                                          action="/reservation-form/#wpcf7-f183-p1492-o1"
                                          method="post"
                                          className="wpcf7-form demo init"
                                          aria-label="Contact form"
                                          noValidate
                                          data-status="init"
                                          onSubmit={handleSubmit}
                                        >
                                          {' '}
                                          <div>
                                            {/* Form Inputs */}
                                            <div className="edgtf-grid-row edgtf-grid-small-gutter cc-u">
                                              <div className="edgtf-grid-col-6 d-none">
                                                <p className="input-name">
                                                  <label htmlFor="user-name">
                                                    User Name
                                                  </label>
                                                  <input
                                                    className="wpcf7-form-control wpcf7-text"
                                                    aria-invalid="false"
                                                    type="text"
                                                    name="text"
                                                    value={userName}
                                                    placeholder="User Name*"
                                                    onChange={(e) =>
                                                      setUserName(
                                                        e.target.value
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  />
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-6">
                                                <p
                                                  className="input-name"
                                                  style={{
                                                    position: 'relative',
                                                  }}
                                                >
                                                  <label
                                                    htmlFor="first-name"
                                                    className={
                                                      errors.firstName
                                                        ? 'ac-label'
                                                        : ''
                                                    }
                                                  >
                                                    First Name
                                                  </label>

                                                  <input
                                                    className={`wpcf7-form-control wpcf7-text ${errors.firstName ? 'error-input-border' : ''}`}
                                                    aria-invalid="false"
                                                    type="text"
                                                    name="firstName"
                                                    value={firstName}
                                                    placeholder="First Name*"
                                                    onChange={(e) =>
                                                      setFirstName(
                                                        e.target.value
                                                      )
                                                    }
                                                    autoComplete="new-password"
                                                  />
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-6">
                                                <p className="input-name">
                                                  <label
                                                    htmlFor="last-name"
                                                    className={
                                                      errors.lastName
                                                        ? 'ac-label'
                                                        : ''
                                                    }
                                                  >
                                                    Last Name
                                                  </label>
                                                  <input
                                                    className={`wpcf7-form-control wpcf7-text ${errors.lastName ? 'error-input-border' : ''}`}
                                                    aria-invalid="false"
                                                    type="text"
                                                    placeholder="Last Name*"
                                                    name="lastName"
                                                    value={lastName}
                                                    onChange={(e) =>
                                                      setLastName(
                                                        e.target.value
                                                      )
                                                    }
                                                    autoComplete="new-password"
                                                  />
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-6">
                                                <p className="input-name">
                                                  <label
                                                    htmlFor="email"
                                                    className={
                                                      errors.email
                                                        ? 'ac-label'
                                                        : ''
                                                    }
                                                  >
                                                    Email
                                                  </label>
                                                  <input
                                                    className={`wpcf7-form-control wpcf7-text ${errors.email ? 'error-input-border' : ''}`}
                                                    aria-invalid="false"
                                                    type="email"
                                                    name="text"
                                                    value={email}
                                                    disabled
                                                    placeholder="Email*"
                                                  />
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-6">
                                                <p className="input-name">
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="date"
                                                  >
                                                    <label
                                                      htmlFor="dob"
                                                      className={
                                                        errors.dob
                                                          ? 'ac-label'
                                                          : ''
                                                      }
                                                    >
                                                      DOB
                                                    </label>
                                                    <input
                                                      placeholder="DOB"
                                                      type="date"
                                                      name="date"
                                                      className={`wpcf7-form-control wpcf7-date wpcf7-validates-as-date ${errors.dob ? 'error-input-border' : ''}`}
                                                      onChange={(e) =>
                                                        setDob(e.target.value)
                                                      }
                                                      value={dob}
                                                    />
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-12 mb-4">
                                                <p className="input-name">
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="date"
                                                  >
                                                    <label htmlFor="gender">
                                                      Gender
                                                    </label>
                                                    <select
                                                      className="form-select mb-0 gender-select"
                                                      value={gender}
                                                      onChange={(e) =>
                                                        setGender(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option value="">
                                                        Select Gender
                                                      </option>
                                                      <option value="MALE">
                                                        Male
                                                      </option>
                                                      <option value="FEMALE">
                                                        Female
                                                      </option>
                                                      <option value="OTHER">
                                                        Other
                                                      </option>
                                                    </select>
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="edgtf-grid-col-12">
                                                <p className="input-name">
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="textarea-984"
                                                  >
                                                    <label
                                                      htmlFor="description"
                                                      className={
                                                        errors.bio
                                                          ? 'ac-label'
                                                          : ''
                                                      }
                                                    >
                                                      Description
                                                    </label>
                                                    <textarea
                                                      className={`wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required ${errors.bio ? 'error-input-border' : ''}`}
                                                      rows="4"
                                                      cols="4"
                                                      value={bio}
                                                      onChange={(e) =>
                                                        setBio(e.target.value)
                                                      }
                                                      placeholder="Describe yourself..."
                                                    />
                                                  </span>
                                                </p>
                                              </div>
                                              {isChangePassword && (
                                                <>
                                                  <div className="edgtf-grid-col-6">
                                                    <p className="input-pass-word icon-eye input-name">
                                                      <label
                                                        htmlFor="password"
                                                        className={
                                                          errors.password
                                                            ? 'ac-label'
                                                            : ''
                                                        }
                                                      >
                                                        Password
                                                      </label>
                                                      <input
                                                        type={
                                                          showPassword
                                                            ? 'text'
                                                            : 'password'
                                                        }
                                                        className={`wpcf7-form-control wpcf7-tel wpcf7-text wpcf7-validates-as-tel ${errors.password ? 'error-input-border' : ''}`}
                                                        name="password"
                                                        value={password}
                                                        onChange={(e) =>
                                                          setPassword(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder="Password*"
                                                        autoComplete="new-password"
                                                      />
                                                      <span
                                                        onClick={
                                                          togglePasswordVisibility
                                                        }
                                                      >
                                                        {showPassword ? (
                                                          <img
                                                            src="../images/eye-password-hide.svg"
                                                            alt=""
                                                          />
                                                        ) : (
                                                          <img
                                                            src="../images/eye-password-show.svg"
                                                            alt=""
                                                          />
                                                        )}
                                                      </span>
                                                    </p>
                                                  </div>

                                                  <div className="edgtf-grid-col-6">
                                                    <p className=" input-name">
                                                      <label
                                                        htmlFor="confirm password"
                                                        className={
                                                          errors.confirmPassword
                                                            ? 'ac-label'
                                                            : ''
                                                        }
                                                      >
                                                        Confirm Password
                                                      </label>
                                                      <div className="input-pass-word icon-eye">
                                                        <input
                                                          type={
                                                            showConfirmPassword
                                                              ? 'text'
                                                              : 'password'
                                                          }
                                                          className={`wpcf7-form-control wpcf7-tel wpcf7-text wpcf7-validates-as-tel ${errors.confirmPassword ? 'error-input-border' : ''}`}
                                                          name="confirmPassword"
                                                          value={
                                                            confirmPassword
                                                          }
                                                          onChange={(e) =>
                                                            setConfirmPassword(
                                                              e.target.value
                                                            )
                                                          }
                                                          placeholder="Confirm Password*"
                                                          autoComplete="new-password"
                                                        />
                                                        <span
                                                          onClick={
                                                            toggleConfirmPasswordVisibility
                                                          }
                                                        >
                                                          {showConfirmPassword ? (
                                                            <img
                                                              src="../images/eye-password-hide.svg"
                                                              alt=""
                                                            />
                                                          ) : (
                                                            <img
                                                              src="../images/eye-password-show.svg"
                                                              alt=""
                                                            />
                                                          )}
                                                        </span>
                                                      </div>
                                                      {errors.confirmPassword && (
                                                        <div className="error d-none">
                                                          {
                                                            errors.confirmPassword
                                                          }
                                                        </div>
                                                      )}
                                                      {customer?.first_name && (
                                                        <span
                                                          onClick={
                                                            handleClosePassword
                                                          }
                                                          className="c-pass-word d-none cp text-end d-block pt-2"
                                                        >
                                                          Close
                                                        </span>
                                                      )}
                                                    </p>
                                                  </div>
                                                </>
                                              )}
                                              <div className="edgtf-grid-col-12">
                                                <div className="edgtf-position-right">
                                                  <div className="edgtf-position-right-inner">
                                                    <div className="text-start mt-3 d-flex align-items-center gap-2 justify-content-between flex-wrap">
                                                      <button
                                                        type="submit"
                                                        className="wpcf7-form-control wpcf7-submit has-spinner edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                                                        disabled={
                                                          isLoading ||
                                                          isImageLoading
                                                        }
                                                      >
                                                        {isLoading
                                                          ? 'Loading...'
                                                          : 'Save Changes'}
                                                      </button>
                                                      <>
                                                        {!isChangePassword && (
                                                          <div className="">
                                                            <div className="tickets">
                                                              <span
                                                                onClick={() =>
                                                                  setIsChangePassword(
                                                                    !isChangePassword
                                                                  )
                                                                }
                                                                className="c-pass-word text-start cp"
                                                              >
                                                                Change password
                                                              </span>
                                                            </div>
                                                          </div>
                                                        )}
                                                        {isChangePassword && (
                                                          <>
                                                            {customer?.first_name && (
                                                              <span
                                                                onClick={
                                                                  handleClosePassword
                                                                }
                                                                className="c-pass-word cp text-end d-block pt-2"
                                                              >
                                                                Close
                                                              </span>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="wpcf7-response-output"
                                            aria-hidden="true"
                                          />
                                          {generalError && (
                                            <div className="error">
                                              {generalError}
                                            </div>
                                          )}
                                        </form>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '60px' }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-0 vc_col-lg-4 vc_col-md-offset-0 vc_col-md-12">
                                  <div className="vc_column-inner vc_custom_1522241954618">
                                    <div className="wpb_wrapper">
                                      <div className="edgtf-single-image-holder">
                                        <div className="mb-4 user-create-image">
                                          <div
                                            className={`user-change-photo user-c-p shadow ${isImageLoading ? ' over-flow ' : ''} `}
                                          >
                                            <img
                                              src={
                                                preview
                                                  ? preview
                                                  : '../images/profile.png'
                                              }
                                              alt="..."
                                            />
                                          </div>
                                          <div className="add-remove-img">
                                            {!customer?.profile_picture && (
                                              <button
                                                type="button"
                                                className="btn warn-ing btn-sm radius-0 p-2"
                                                onClick={handleButtonClick}
                                                disabled={isImageLoading}
                                              >
                                                <img
                                                  src="../images/edit.png"
                                                  alt=""
                                                />
                                              </button>
                                            )}
                                            {customer?.profile_picture && (
                                              <button
                                                type="button"
                                                className="btn btn-outline-danger dan-ger btn-sm radius-0 p-2"
                                                onClick={(e) =>
                                                  handleDelete(
                                                    e,
                                                    customer.profile_picture.id
                                                  )
                                                }
                                                disabled={isImageLoading}
                                              >
                                                <img
                                                  src="../images/delete.png"
                                                  alt=""
                                                />
                                              </button>
                                            )}
                                          </div>
                                          <input
                                            type="file"
                                            id="fileInput"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: '30px' }}
                                      >
                                        <span className="vc_empty_space_inner" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* new chat end */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileUpdate;
