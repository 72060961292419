import React, { useEffect, useState } from 'react';
import Header from '../../Home/Components/Header';
import Footer from '../../Home/Components/Footer';
import { useLocation } from 'react-router-dom';
import EventCard from '../../Home/Components/EventCard';
import axiosInstance from '../../../../Utils/axiosInstance';
import { EVENTS_ENDPOINT } from '../../../../Utils/Endpoints';
import Logger from '../../../../Utils/Logger';
import moment from 'moment';
import Pagination from '../../../Components/Pagination';

const SearchResults = () => {
  const [eventList, setEventList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [searchtext, setSearchText] = useState(
    location.state && location.state.query
  );
  const [searchQuery, setSearchQuery] = useState('');

  // Pagination
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (searchtext) {
      fetchEvent();
    }
  }, [searchtext, currentPage]);

  const fetchEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENTS_ENDPOINT +
          `?search=${searchtext ? searchtext : searchQuery}&event_start_from=${moment().unix()}&page=${currentPage}&per_page=${itemsPerPage}`
      );
      if (response.data.code === 200) {
        setEventList(response.data.data.data);
        setTotalItems(response.data.data.total);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="edgtf-woocommerce-page  edgtf-predefined-text-skin edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />
            {/* new html start */}
            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div
                  className="pt-4 edgtf-title-holder edgtf-standard-with-breadcrumbs-type edgtf-title-va-header-bottom"
                  style={{ backgroundColor: '#121212', height: 'auto' }}
                >
                  <div className="edgtf-title-wrapper">
                    <div className="edgtf-title-inner">
                      <div className="edgtf-grid">
                        <div className="edgtf-title-info">
                          <h4 className="edgtf-page-title entry-title">
                            <span className="c-g">Search results for : </span>
                            {''}
                            {searchtext ? searchtext : searchQuery}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="edgtf-container">
                    <div className="edgtf-container-inner clearfix pt-4">
                      <div className="edgtf-grid-row">
                        <div className="edgtf-page-content-holder edgtf-grid-col-12">
                          <div className="edgtf-search-page-holder">
                            <div className="edgtf-search-page-form">
                              <h2 className="edgtf-search-title d-none">
                                New search:
                              </h2>
                              <div className="edgtf-form-holder d-none">
                                <div className="edgtf-column-left">
                                  <input
                                    type="text"
                                    name="search"
                                    className="edgtf-search-field"
                                    autoComplete="off"
                                    placeholder="Type here"
                                    value={searchQuery}
                                    onChange={(e) => {
                                      setSearchQuery(e.target.value);
                                      setSearchText('');
                                    }}
                                  />
                                </div>
                                <div className="edgtf-column-right">
                                  <button
                                    type="button"
                                    className="edgtf-search-submit"
                                    onClick={fetchEvent}
                                  >
                                    <span className="icon_search"></span>
                                  </button>
                                </div>
                              </div>
                              <div className="edgtf-search-label d-none">
                                If you are not happy with the results below,
                                please do another search
                              </div>
                            </div>
                            {!isLoading && eventList?.length === 0 && (
                              <p className="edgtf-blog-no-posts">
                                No posts were found.
                              </p>
                            )}
                            {!isLoading && eventList?.length > 0 && (
                              <div className="vc_row wpb_row vc_row-fluid">
                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                  <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                      {/* Events Listing START */}
                                      <div className="events-listing pt-0 px-0 pb-0">
                                        <var className="edgtf-st-tagline d-none">
                                          Upcoming Events{' '}
                                        </var>
                                        <div className="outer outer-new car-t">
                                          {!isLoading &&
                                            eventList?.map((item, index) => (
                                              <EventCard
                                                event={item}
                                                imageUser={
                                                  item?.banner?.fullUrl
                                                    ? item?.banner?.fullUrl
                                                    : '../images/thumbnail.png'
                                                }
                                                heading={item?.name}
                                                text={item?.startDateTimeFormat}
                                                key={index}
                                              />
                                            ))}
                                        </div>
                                        <div>
                                          {totalItems > 10 && (
                                            <Pagination
                                              itemsPerPage={itemsPerPage}
                                              totalItems={totalItems}
                                              currentPage={currentPage}
                                              onPageChange={handlePageChange}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      {/* Events Listing END */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* new html end */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResults;
