import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  getLoginCustomer,
  logoutCustomer,
} from '../../../../Utils/LocalStorage';
// import axiosInstance from '../../../../Utils/axiosInstance';
// import { CART_LIST_ENDPOINT } from '../../../../Utils/Endpoints';
import Logger from '../../../../Utils/Logger';

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const customer = getLoginCustomer();
  const [searchQuery, setSearchQuery] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cartList, setCartList] = useState({});

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      searchFunction(searchQuery);
    }
  };

  const searchFunction = (query) => {
    // Implement your search logic here
    navigate('/search', {
      state: { query },
    });
  };

  const handleNavigation = (url) => {
    navigate(url);
  };

  const handleSignOut = async () => {
    await logoutCustomer();
    await handleNavigation('/');
  };

  // new add
  const handleClick = () => {
    setIsActive(true);
  };

  const handleCloseClick = (event) => {
    event.preventDefault();
    setIsActive(false);
  };

  useEffect(() => {
    if (customer?.id) {
      fetchCart();
    }
  }, []);

  const fetchCart = async () => {
    try {
      setIsLoading(true);
      // const response = await axiosInstance.get(CART_LIST_ENDPOINT);
      // if (response.data.code === 200) {
      //   if (response?.data?.data?.cart !== 'Your cart is empty.') {
      //     setCartList(response.data.data);
      //   } else {
      setCartList({});
      //   }
      // }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {customer?.id && (
        <div className="edgtf-top-bar ed-top-bar d-lg-none d-lg-block">
          <div className="edgtf-vertical-align-containers px-0">
            {/* Right Section */}
            <div className="">
              <div className="edgtf-position-right-inner justify-content-center">
                {/* Sign in */}
                <Link
                  className={'edgtf-icon-widget-holder'}
                  to="/profile-update"
                  target="_self"
                  style={{ margin: '0 15px 0 15px' }}
                >
                  <span
                    className={`edgtf-icon-text edgtf-no-icon  ${pathname === '/profile-update' ? 'tp-active' : ''}`}
                  >
                    My Profile
                  </span>
                </Link>
                {/* Separator */}
                <div className="widget edgtf-separator-widget">
                  <div className="edgtf-separator-holder clearfix edgtf-separator-center edgtf-separator-normal">
                    <div
                      className="edgtf-separator"
                      style={{
                        borderColor: '#4d4d4d',
                        borderStyle: 'solid',
                        width: '1px',
                        borderBottomWidth: '15px',
                      }}
                    ></div>
                  </div>
                </div>
                {/* Our Menu */}
                <Link
                  className="edgtf-icon-widget-holder "
                  to="/my-order"
                  target="_self"
                  style={{ margin: '0 15px 0 15px' }}
                >
                  <span
                    className={`edgtf-icon-text edgtf-no-icon ${pathname === '/my-order' ? 'tp-active' : ''}`}
                  >
                    My Order
                  </span>
                </Link>
                {/* Separator */}
                <div className="widget edgtf-separator-widget">
                  <div className="edgtf-separator-holder clearfix edgtf-separator-center edgtf-separator-normal">
                    <div
                      className="edgtf-separator"
                      style={{
                        borderColor: '#4d4d4d',
                        borderStyle: 'solid',
                        width: '1px',
                        borderBottomWidth: '15px',
                      }}
                    ></div>
                  </div>
                </div>
                {/* My Account */}
                <Link
                  className="edgtf-icon-widget-holder"
                  onClick={handleSignOut}
                  style={{ margin: '0 15px 0 15px' }}
                >
                  <span className="edgtf-icon-text edgtf-no-icon">Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* new header */}
      <header className="edgtf-page-header top-header-customer hh-ss">
        <div className="edgtf-menu-area edgtf-menu-left">
          <div className="edgtf-vertical-align-containers">
            <div className="edgtf-position-left d-flex">
              <div className="edgtf-position-left-inner">
                <div className="edgtf-logo-wrapper">
                  <Link to="/">
                    <img
                      width="40"
                      height="40"
                      alt="logo"
                      src="../images/emblem.png"
                    />
                    PartyPass
                  </Link>
                </div>
                <nav className="edgtf-main-menu edgtf-drop-down edgtf-default-nav menu-hh">
                  {pathname !== '/login' &&
                    pathname !== '/signup' &&
                    pathname !== '/forgot' && (
                      <ul
                        id="menu-main-menu-navigation d-none"
                        className="clearfix"
                      >
                        <li
                          className={`menu-item ${pathname === '/' ? 'edgtf-active-item' : ''} `}
                        >
                          <Link to="/" className="current ">
                            <span className="item_outer">
                              <span className="item_text">Home</span>
                            </span>
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link to="#" className="current">
                            <span className="item_outer">
                              <span className="item_text">Events</span>
                            </span>
                          </Link>
                        </li>
                      </ul>
                    )}
                </nav>
              </div>
            </div>
            {pathname !== '/login' &&
              pathname !== '/signup' &&
              pathname !== '/forgot' && (
                <div className="edgtf-position-right ">
                  <div className="edgtf-position-right-inner">
                    {customer?.id && (
                      <div className="edgtf-top-bar">
                        <div className="edgtf-vertical-align-containers pe-0">
                          <div className="edgtf-position-right-inner">
                            {/*  My Account  */}
                            <Link
                              className={'edgtf-icon-widget-holder'}
                              to="/profile-update"
                              target="_self"
                              style={{ margin: '0 15px 0 15px' }}
                            >
                              <span
                                className={`edgtf-icon-text edgtf-no-icon  ${pathname === '/profile-update' ? 'tp-active' : ''}`}
                              >
                                My Profile
                              </span>
                            </Link>
                            {/* Separator */}
                            <div className="widget edgtf-separator-widget">
                              <div className="edgtf-separator-holder clearfix edgtf-separator-center edgtf-separator-normal">
                                <div
                                  className="edgtf-separator"
                                  style={{
                                    borderColor: '#4d4d4d',
                                    borderStyle: 'solid',
                                    width: '1px',
                                    borderBottomWidth: '15px',
                                  }}
                                ></div>
                              </div>
                            </div>
                            {/* MY Order */}
                            <Link
                              className="edgtf-icon-widget-holder "
                              to="/my-order"
                              target="_self"
                              style={{ margin: '0 15px 0 15px' }}
                            >
                              <span
                                className={`edgtf-icon-text edgtf-no-icon ${pathname === '/my-order' ? 'tp-active' : ''}`}
                              >
                                My Order
                              </span>
                            </Link>
                            {/* Separator */}
                            <div className="widget edgtf-separator-widget">
                              <div className="edgtf-separator-holder clearfix edgtf-separator-center edgtf-separator-normal">
                                <div
                                  className="edgtf-separator"
                                  style={{
                                    borderColor: '#4d4d4d',
                                    borderStyle: 'solid',
                                    width: '1px',
                                    borderBottomWidth: '15px',
                                  }}
                                ></div>
                              </div>
                            </div>
                            {/* My Account */}
                            <Link
                              className="edgtf-icon-widget-holder"
                              onClick={handleSignOut}
                              style={{ margin: '0 15px 0 15px' }}
                            >
                              <span className="edgtf-icon-text edgtf-no-icon">
                                Logout
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="edgtf-search-opener edgtf-icon-has-hover edgtf-search-opener-icon-pack"
                      onClick={handleClick}
                      style={{ color: '#ffffff', marginRight: '20px' }}
                    >
                      <span className="edgtf-search-opener-wrapper">
                        <span
                          aria-hidden="true"
                          className="edgtf-icon-font-elegant icon_search"
                        ></span>
                      </span>
                    </div>
                    {customer?.id ? (
                      <>
                        <div className="edgtf-shopping-cart-holder">
                          <div className="edgtf-shopping-cart-inner">
                            <Link
                              itemProp="url"
                              className="edgtf-header-cart edgtf-header-cart-icon-pack"
                              to="/cart"
                            >
                              <span className="edgtf-shopping-cart-icon-inner">
                                <span className="edgtf-cart-icon">
                                  <span
                                    aria-hidden="true"
                                    className="edgtf-icon-font-elegant icon_cart"
                                  ></span>
                                </span>
                                {!isLoading &&
                                  Object.keys(cartList).length > 0 && (
                                    <span className="edgtf-cart-number">
                                      {
                                        Object.entries(cartList?.cart?.items)
                                          .length
                                      }
                                    </span>
                                  )}
                              </span>
                            </Link>
                            <div className="edgtf-shopping-cart-dropdown d-none">
                              <ul>
                                <li className="edgtf-empty-cart">
                                  No products in the cart.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          onClick={() => handleNavigation('/signup')}
                          style={{ color: '#ffffff', borderColor: '#ffffff' }}
                          className="edgtf-btn edgtf-btn-custom-border-hover edgtf-btn-custom-hover-bg edgtf-btn-custom-hover-color edgtf-btn-outline text-nowrap"
                          data-hover-color="#000000"
                          data-hover-bg-color="#ffffff"
                          data-hover-border-color="#ffffff"
                        >
                          <span className="edgtf-btn-text">Sign Up</span>
                        </button>
                        <Link
                          to="/login"
                          style={{ marginLeft: '20px' }}
                          className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                        >
                          <span className="edgtf-btn-text">Login</span>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="edgtf-search-cover">
          <div className="edgtf-form-holder-outer">
            <div className="edgtf-form-holder">
              <div className="edgtf-form-holder-inner">
                <input
                  type="text"
                  placeholder="Search here..."
                  name="search"
                  className="edgtf_search_field"
                  autoComplete="off"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={handleSearch}
                />
                <Link
                  className="edgtf-search-close edgtf-search-close-icon-pack"
                  to="#"
                >
                  <span
                    aria-hidden="true"
                    className="edgtf-icon-font-elegant icon_close "
                  ></span>{' '}
                </Link>
              </div>
            </div>
          </div>
        </div>
        {isActive && (
          <div
            className="edgtf-search-cover edgtf-is-active"
            style={{
              height: '98px',
              display: 'block',
              opacity: '1',
              position: 'absolute',
            }}
          >
            <div className="edgtf-form-holder-outer">
              <div className="edgtf-form-holder">
                <div className="edgtf-form-holder-inner">
                  <input
                    type="text"
                    placeholder="Search here..."
                    name="search"
                    className="edgtf_search_field text-white mb-0 border-0 place"
                    autoComplete="off"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleSearch}
                  />
                  <a
                    className="edgtf-search-close edgtf-search-close-icon-pack"
                    onClick={handleCloseClick}
                  >
                    <span
                      aria-hidden="true"
                      className="edgtf-icon-font-elegant icon_close"
                    ></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
      <Link id="edgtf-back-to-top" to="#">
        <span className="edgtf-icon-stack edgtf-front-side">
          <span
            aria-hidden="true"
            className="edgtf-icon-font-elegant arrow_triangle-up "
          ></span>{' '}
        </span>
        <span className="edgtf-icon-stack edgtf-back-side">
          <span
            aria-hidden="true"
            className="edgtf-icon-font-elegant arrow_triangle-up "
          ></span>{' '}
        </span>
      </Link>
      {/* new header */}
    </>
  );
};

export default Header;
