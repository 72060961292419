import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const FollowShare = ({ shareList }) => {
  function getIconClass(type) {
    const iconClasses = {
      APPLE_MUSIC: 'social_wordpress_circle',
      FACEBOOK: 'social_facebook_circle',
      INSTAGRAM: 'social_instagram_circle',
      SPOTIFY: 'social_spotify_circle',
      TIKTOK: 'social_tiktok_circle',
      TWITTER: 'social_twitter_circle',
      WEB: 'icon_link_alt',
      YOUTUBE: 'social_youtube_circle',
    };
    return iconClasses[type] || 'icon_circle'; // Default icon class
  }

  const socialMediaLinks = shareList
    ?.filter((item) => item.url) // Filter items with a non-empty URL
    .map((item) => ({
      type: item.type,
      iconClass: getIconClass(item.type), // Function to get the icon class based on type
      shareText: `Share on ${item.typeName}`,
      url: item.url,
    }));

  return (
    <>
      {socialMediaLinks?.length > 0 && (
        <div className="offbeat-event-social content-center mt-3">
          <div className="edgtf-social-share-holder edgtf-list">
            <h4 className="edgtf-social-title d-block pb-3">
              Follow and Share
            </h4>
            <ul>
              {socialMediaLinks.map((media, index) => (
                <li
                  key={index}
                  className={`edgtf-${media.type.toLowerCase()}-share`}
                >
                  <Link
                    itemProp="url"
                    className="edgtf-share-link"
                    onClick={() =>
                      window.open(
                        media.url,
                        'popupwindow'
                        // 'scrollbars=yes,width=800,height=1200'
                      )
                    }
                  >
                    <span
                      className={`edgtf-social-network-icon ${media.iconClass}`}
                    ></span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

FollowShare.propTypes = {
  shareList: PropTypes.any,
};
export default FollowShare;
