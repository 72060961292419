import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  EVENTS_ENDPOINT,
  ORGANIZATION_FRONT_ENDPOINT,
} from '../../../../Utils/Endpoints';
import Logger from '../../../../Utils/Logger';
import EventCard from '../Components/EventCard';
import PropTypes from 'prop-types';
import MapComponent from './MapComponent';
import FollowShare from './FollowShare';
import NotFound from '../../../../Components/NotFound';
import moment from 'moment';
import Pagination from '../../../Components/Pagination';

const OrgEvent = ({ orgId }) => {
  const [organization, setOrganization] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [eventList, setEventList] = useState([]);
  // Pagination
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  useEffect(() => {
    fetchEvent();
  }, [currentPage]);

  const fetchOrganization = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORGANIZATION_FRONT_ENDPOINT + `/${orgId}`
      );
      if (response.data.code === 200) {
        setOrganization(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENTS_ENDPOINT +
          `?organization_id=${orgId}&event_start_from=${moment().unix()}&page=${currentPage}&per_page=${itemsPerPage}`
      );
      if (response.data.code === 200) {
        setEventList(response.data.data.data);
        setTotalItems(response.data.data.total);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {organization && (
        <div className="edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
          <div className="edgtf-wrapper">
            <div className="edgtf-wrapper-inner">
              <Header />
              {/* new html start */}
              {!isLoading && Object.keys(organization)?.length > 0 && (
                <div className="edgtf-content">
                  <div className="edgtf-content-inner">
                    <div
                      className="edgtf-title-holder edgtf-standard-type edgtf-title-va-header-bottom"
                      style={{ height: '150px', backgroundColor: '#000000' }}
                      data-height="150"
                    >
                      <div
                        className="edgtf-title-wrapper"
                        style={{ height: '150px' }}
                      >
                        <div className="edgtf-title-inner">
                          <div className="edgtf-grid">
                            <h2 className="edgtf-page-title entry-title">
                              {organization?.name}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="offbeat-event-single-holder">
                      <div className="edgtf-full-width">
                        <div className="edgtf-full-width-inner pt-0">
                          <div className="edgtf-grid p-l-r">
                            <div className="offbeat-event-top-holder edgtf-grid-row edgtf-grid-huge-gutter preview-tt">
                              <div className="offbeat-event-top-right edgtf-grid-col-4 off-beat px-xl-0">
                                <div className="offbeat-event-featured-image pt-1">
                                  <img
                                    width="800"
                                    height="943"
                                    src={
                                      organization?.banner?.fullUrl
                                        ? organization?.banner?.fullUrl
                                        : '../images/thumbnail.png'
                                    }
                                    className="attachment-full size-full wp-post-image hh-img"
                                    alt="efqw"
                                    decoding="async"
                                    fetchPriority="high"
                                    sizes="(max-width: 800px) 100vw, 800px"
                                  />
                                </div>
                                <div className="user-ticket-details ">
                                  <h4 className="offbeat-event-info-title pt-0">
                                    Details
                                  </h4>
                                  <div className="offbeat-event-info-item offbeat-event-date">
                                    <span className="offbeat-event-info-item-title">
                                      Address:
                                    </span>

                                    <span className="offbeat-event-info-item-desc">
                                      {organization?.location?.addressString}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="offbeat-event-top-left edgtf-grid-col-8 ">
                                <div className="offbeat-event-content-wrap clearfix pb-5  pt-4 pt-xl-0">
                                  <div className="offbeat-event-text-main content-center">
                                    <div className="offbeat-event-content">
                                      <div className="wpb-content-wrapper">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1519891401815">
                                          <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                              <div className="wpb_wrapper">
                                                <div className="edgtf-google-map-holder">
                                                  <div
                                                    className="edgtf-google-map"
                                                    style={{
                                                      minHeight: '350px',
                                                      maxHeight: '350px',
                                                    }}
                                                  >
                                                    <MapComponent
                                                      lat={
                                                        organization?.location
                                                          ?.lat
                                                      }
                                                      lng={
                                                        organization?.location
                                                          ?.lng
                                                      }
                                                      city={
                                                        organization?.location_type ===
                                                        'CITY'
                                                          ? organization
                                                              ?.location
                                                              ?.addressString
                                                          : null
                                                      }
                                                    />
                                                  </div>
                                                  <div className="edgtf-google-map-overlay"></div>
                                                </div>
                                                <FollowShare
                                                  shareList={
                                                    organization?.socialMedia
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {!isLoading && eventList?.length > 0 && (
                                <div className="offbeat-event-top-left edgtf-grid-col-12 px-md-2 pb-5">
                                  <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                      <div className="vc_column-inner">
                                        <div className="wpb_wrapper">
                                          {/* Events Listing START */}
                                          <div className="events-listing pt-5 px-0">
                                            <var className="edgtf-st-tagline">
                                              Upcoming Events{' '}
                                            </var>
                                            <div className="outer outer-new outer-new-list">
                                              {!isLoading &&
                                                eventList?.map(
                                                  (item, index) => (
                                                    <EventCard
                                                      event={item}
                                                      imageUser={
                                                        item?.banner?.fullUrl
                                                          ? item?.banner
                                                              ?.fullUrl
                                                          : '../images/thumbnail.png'
                                                      }
                                                      heading={item?.name}
                                                      text={
                                                        item?.startDateTimeFormat
                                                      }
                                                      key={index}
                                                    />
                                                  )
                                                )}
                                            </div>
                                            <div>
                                              {totalItems > 10 && (
                                                <Pagination
                                                  itemsPerPage={itemsPerPage}
                                                  totalItems={totalItems}
                                                  currentPage={currentPage}
                                                  onPageChange={
                                                    handlePageChange
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div>
                                          {/* Events Listing END */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* new html start */}
              <Footer />
            </div>
          </div>
        </div>
      )}
      {!organization && <NotFound />}
      <div className="promo-mode d-none">
        <h4>Preview Mode</h4>
        <p>
          There&apos;s a chance this page is not yet published. Don&apos;t share
          this link with anyone.
        </p>
      </div>
    </>
  );
};

OrgEvent.propTypes = {
  orgId: PropTypes.string,
};

export default OrgEvent;
