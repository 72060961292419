import Login from '../Customer/Pages/Auth/Login/Login';
import Forgot from '../Customer/Pages/Auth/PasswordForgot/Forgot';
import Signup from '../Customer/Pages/Auth/Signup/Signup';

// Public Routes
export const publicRoutes = [
  { path: 'login', element: <Login />, redirectTo: '/' },
  { path: 'signup', element: <Signup />, redirectTo: '/' },
  { path: 'forgot', element: <Forgot />, redirectTo: '/' },
];
