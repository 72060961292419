import ProfileUpdate from '../Customer/Pages/Auth/Profile/ProfileUpdate';
import MyOrder from '../Customer/Pages/Auth/MyOrder/MyOrder';
import OrderDetails from '../Customer/Pages/Auth/MyOrder/OrderDetails';
import CheckOut from '../Customer/Pages/Home/Events/CheckOut';
import ThankYouCheckOut from '../Customer/Pages/Home/Events/ThankYouCheckOut';

// Private Routes
export const privateRoutes = [
  { path: 'profile-update', element: <ProfileUpdate /> },
  { path: 'my-order', element: <MyOrder /> },
  { path: 'order-details', element: <OrderDetails /> },
  { path: 'check-out', element: <CheckOut /> },
  { path: 'thank-you-check-out', element: <ThankYouCheckOut /> },
];
